import React from 'react'
import styled from 'styled-components'
import RichText from '../RichText'
import { useSalesSupplyOrderReturn } from '../../hooks/useThirdPartyScripts'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  headerHeight,
  color,
  border
} from '../../styles'

const ReturnsBody = ({ title, disclaimer }) => {
  useSalesSupplyOrderReturn()

  return (
    <StyledReturnsBody>
      <Title>{title}</Title>
      <Wrapper>
        <div id='returnDiv' />
        <Disclaimer>
          <RichText json={JSON.parse(disclaimer.disclaimer)} />
        </Disclaimer>
      </Wrapper>
    </StyledReturnsBody>
  )
}

const StyledReturnsBody = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(${headerHeight.mobile} + ${mobileVW(30)});
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: calc(${headerHeight.desktop} + ${desktopVW(60)});
  }
`

const Title = styled.h1`
  font-size: ${mobileVW(60)};
  line-height: ${mobileVW(51)};
  word-break: break-word;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(150)};
    line-height: ${desktopVW(142)};
    padding: 0 ${desktopVW(200)};
    word-break: break-word;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${mobileVW(16)};
  top: -${mobileVW(30)};

  #returnDiv {
    font-family: inherit!important;
    background-color: ${color.yellow};
    padding: ${mobileVW(50)} ${mobileVW(50)} ${mobileVW(25)};

    #currentStepTitle {
      display: none;
    }

    .dot, .littleDot {
      display: none;
    }

    #mainTitle {
      margin: ${mobileVW(20)} 0;
      color: ${color.black};
    }

    #backLink {
      top: ${mobileVW(16)};
      left: ${mobileVW(16)};
      color: ${color.black};
    }

    #reasonError {
      color: ${color.red};
    }

    .step {
      .inputElement {
        font-size: ${mobileVW(16)};
        line-height: ${mobileVW(24)};
        color: ${color.black};
        font-weight: bold;
        border: none;
        border-bottom: ${border.medium};
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        margin-bottom: ${mobileVW(16)};
        
        &::placeholder {
          color: ${color.black};
        }

        &:focus {
          outline: none;
        }
      }

      .returnFormButton {
        font-size: ${mobileVW(12)};
        line-height: ${mobileVW(10)};
        border-radius: 0;
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        height: ${mobileVW(65)};
        justify-content: center;
        align-items: center;
        width: 100%;
        cursor: pointer;
        position: relative;
        bottom: 0;
        background-color: ${color.black};
        color: ${color.white};
      }

      #returnItemsTable { 
        .disabledItemIcon {
          display: none;
        }

        td {
          width: auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          img {
            width: auto;
            max-height: ${mobileVW(283)};
          }
        }

        .returnFormSelected {
          border: ${border.medium}!important;

          td:last-child:after {
            display: none;
          }
        }
      }

      #reasonsDiv {
        .reasonButton {
          border-radius: 0;
          border: ${border.thin};

          &.returnFormSelected {
            border: ${border.medium};
          }
        }
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVW(600)};
    top: -${desktopVW(110)};

    #returnDiv {
      padding: ${desktopVW(60)} ${desktopVW(60)} ${desktopVW(30)};

      #mainTitle {
        margin: ${desktopVW(20)} 0;
      }
  
      #backLink {
        top: ${desktopVW(60)};
        left: ${desktopVW(60)};
      }
  
      .step {
        .inputElement {
          font-size: ${desktopVW(16)};
          line-height: ${desktopVW(24)};
          margin-bottom: ${desktopVW(20)};
        }
  
        .returnFormButton {
          font-size: ${desktopVW(13)};
          line-height: ${desktopVW(20)};
          height: ${desktopVW(65)};
        }

        #returnItemsTable { 
          td {
            img {
              max-height: ${desktopVW(420)};
            }
          }
        }
      }
    }
  }
`

const Disclaimer = styled.div`
  background: ${color.yellow};
  padding: 0 ${mobileVW(50)} ${mobileVW(25)};

  p {
    font-size: ${mobileVW(12)};
    line-height: ${mobileVW(14)};
    text-transform: initial;
  }

  b {
    text-transform: initial;
  }
  

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 ${desktopVW(60)} ${desktopVW(30)};

    p{
      font-size: ${desktopVW(12)};
      line-height: ${desktopVW(14)};
    }
  }
`

export default ReturnsBody
