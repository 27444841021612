import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import ReturnsBody from '../components/returns/ReturnsBody'

const Returns = ({
  data: {
    contentfulReturnsPage: {
      title,
      slug,
      seoTitle,
      seoDescription,
      disclaimer
    }
  }
}) => {
  return (
    <>
      <SEO slug={slug} title={seoTitle} description={seoDescription} isPage />
      <ReturnsBody title={title} disclaimer={disclaimer} />
    </>
  )
}

export const PageQuery = graphql`
  query Returns($node_locale: String!) {
    contentfulReturnsPage(node_locale: { eq: $node_locale }) {
      title
      slug
      seoTitle
      seoDescription
      disclaimer {
        disclaimer
      }
    }
  }
`

export default Returns
